import 'bootstrap/scss/bootstrap.scss'
import {bootstrap} from 'bootstrap'
import {slideToggle, toggleText} from './modules/pure.js'
import News from './modules/news.js'
import observer from "./modules/imageLoad.js";

document.addEventListener('DOMContentLoaded',()=>{
	News()
	observer()
	let dh = document.querySelectorAll('[data-href]')
		for (let index = 0; index < dh.length; index++) {
			const element = dh[index];
			element.addEventListener('click',function (e) {
				let adr = e.currentTarget.dataset.href
				location.href=adr
			})
		}
		document.querySelector('#bvi-trigger').addEventListener('click',function(e){
				document.querySelector('[data-bvi="close"]').click()
			})

		bviToggle('.footer-bvi-box')
		bviToggle('.bviText')
		function bviToggle(cl){
			document.querySelectorAll(cl).forEach(item => {
				item.addEventListener('click',(e) => {
					if(e.currentTarget.classList.contains('bvi-hide')){
						document.querySelector('.bvi-open').click()
					}else{
						document.querySelector('[data-bvi="close"]').click()
					}
				})
			})
		}

	let requisites = document.querySelector('.contact-requisites')
	let expand = document.querySelector('.expand')
	let contactRequisites = document.querySelector('.contact-requisites')
	if(requisites){
		expand.addEventListener('click',(e) => {
			let elem = e.currentTarget
			elem.classList.toggle('active')
			toggleText(elem,'Развернуть','Свернуть')
			slideToggle(contactRequisites,200)
		})
	}

})



